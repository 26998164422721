import React from 'react'
import {Container} from 'react-bootstrap'
import * as styles from './TabWrapper.module.scss'

const TabWrapper = ({children}) => {
  return (
    <section className={styles.tabWrapper}>
      <Container fluid="lg">
        {children}
      </Container>
    </section>
  )
}

export default TabWrapper
