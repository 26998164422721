import { useStaticQuery, graphql } from 'gatsby'
const useGravityData = () => {
  const { allGfForm } = useStaticQuery(
    graphql`
        query {
            allGfForm {
                edges {
                    node {
                        ...GravityFormComponent
                    }
                }
            }
        }
    `
  )
  return allGfForm
}

export default useGravityData