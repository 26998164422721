import React from 'react'
import GravityFormForm from 'gatsby-gravityforms-component'
import useGravityData from '../../hooks/use-gravity-data'

const handleError = ({values, error, reset}) => {
  // console.log('Error', values, errors, reset);
  //handle error
}

const handleSuccess = ({values, reset, confirmations}) => {
  // console.log('Success', values, reset, confirmations);
  //handle success
}

const ProcopioForm = ({formId = 1, presetValues = null, customSuccess, customError }) => {
  const allGravityData = useGravityData()
  const presets = {}
  // map desired presets to the machine id, find by label.
  if (presetValues) {
    const form = allGravityData?.edges.filter(obj => obj?.node?.formId === formId)
    if (form?.length) {
      Object.keys(presetValues).map((key) => {
        const field = form[0]?.node?.formFields.find(obj => obj?.label === key)
        if (field?.id) {
          presets[`input_${field.id}`] = presetValues[key]
        }
      })
    }
  }

  return (
    <GravityFormForm
      id={formId}
      formData={allGravityData}
      presetValues={presets}
      lambda={process.env.GATSBY_GRAVITYFORMS_ENDPOINT}
      successCallback={customSuccess || handleSuccess}
      errorCallback={customError || handleError}
    />
  )
}
export default ProcopioForm