import React from 'react'
import { Col } from 'react-bootstrap'
import EventDate from '../../../helpers/EventDate'
import StyledCard from '../../../components/Common/StyledCard'

const EventCards = ({ ...props }) => {
	const { events, title, label, labelId, backLink } = props

	return events.map((post, index) => {
		const event = post.hasOwnProperty('event') ? post.event : post;
		const eventStartDate = event.eventInformation?.startDate ? new EventDate(event.eventInformation?.startDate) : null
		const eventEndDate = event.eventInformation?.endDate ? new EventDate(event.eventInformation?.endDate) : null
		let eventDate = `<time datetime="${event.eventInformation?.startDate}">${eventStartDate?.monthLong} ${eventStartDate?.date}`
		if (
			eventEndDate?.date &&
			event.eventInformation?.endDate !== event.eventInformation?.startDate
		) {
			eventDate += ` – ${
				eventEndDate?.monthLong !== eventStartDate?.monthLong
					? eventEndDate?.monthLong
					: ''
			} ${eventEndDate?.date}`
		}
		eventDate += `, ${eventStartDate?.year}`
		eventDate += event.eventInformation?.time
			? `<br /> ${event.eventInformation?.time}`
			: ''
		eventDate += '</time>'
		const eventDateWrapper = (
			<div
				dangerouslySetInnerHTML={{ __html: eventDate }}
			/>
		)

		return (
			<Col sm={6} md={4} key={index}>
				<StyledCard
					backLink={backLink}
					variation="CardC"
					service={event?.resourceInformation?.service}
					label={label}
					labelId={labelId}
					href={event.uri}
					title={event.title}
					headingSize="title--sm"
					image={
						event.featuredImage?.node?.localFile?.childImageSharp
							?.gatsbyImageData
					}
					eventDetails={{
						date: eventDateWrapper,
					}}
					imageSize="medium"
				/>
			</Col>
		)
	})
}

export default EventCards
